import * as Sentry from '@sentry/browser'

const options = {
    dsn: document.documentElement.dataset.sentryDsn || ''
}

if (document.documentElement.dataset.sentryEnvironment) {
    options.environment = document.documentElement.dataset.sentryEnvironment
}

if (options.dsn) {
    Sentry.init(options)
    Sentry.configureScope(scope => {
        scope.setTag('logger', 'js')
        scope.setTag('module', 'frontend')
    })
}