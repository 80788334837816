// set basepath of project for webpack
import { basePath, sentryConfig } from './core/config';
__webpack_public_path__ = `${basePath}/assets/frontend/js/`;

import './core/Sentry';

import 'console-polyfill';
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)
import 'custom-event-polyfill'
import './polyfills/Element.append'
import './polyfills/Element.prepend'

import $ from 'jquery';

// jquery utils
import 'jquery.easing';

import './plugins/nette.ajax';
import './plugins/nette.ajax.history'
import './plugins/giant.trigger';
import './plugins/giant.autosubmit';

// nette
import './nette/NetteValidationMessages';
import './nette/NetteSnippets';
import './nette/NetteEvents';

import Nette from './nette/NetteForms';
window.Nette = Nette;
Nette.initOnLoad();

import lazySizes from 'lazysizes'
lazySizes.cfg.lazyClass = 'is-lazy'
lazySizes.cfg.loadingClass = 'is-lazy-loading'
lazySizes.cfg.loadedClass = 'is-lazy-loaded'

import { android, iOS, IE, CMS } from './core/config'

if ($.nette && !CMS) {
    $.nette.init()
}

import TouchMouseInputResolver from './meta/TouchMouseInputResolver';
new TouchMouseInputResolver();

if (IE) {
    document.documentElement.classList.add('is-explorer')
}

if (iOS) {
    document.documentElement.classList.add('is-iphone')
}

import ScrollService from './services/Scroll'
if (IE || android || iOS || CMS || (typeof DISABLE_SMOOTH_SCROLL !== 'undefined' && DISABLE_SMOOTH_SCROLL) || document.body.classList.contains('u-nativeScroll')) {
    ScrollService.useNative()
    setScrollPosition()
} else {
    ScrollService.useVirtual()
        .catch(() => ScrollService.useNative())
        .finally(() => setScrollPosition())
}


import { loadComponents, removeComponents } from './core/componentUtils';
loadComponents();


import Swup from 'swup'
import SwupBodyClassPlugin from '@swup/body-class-plugin'
import SwupJsPlugin from '@swup/js-plugin'
import { queryAll } from './utils/dom'
import EventBus from './core/EventBus'
import Component from './core/Component'
import { runScriptsInsideElement } from './utils/script'

function setScrollPosition(x = null, y = null) {
    const id = window.location.hash.replace('#', '')
    const anchor = id ? document.getElementById(id) : null

    if (id && anchor) {
        const animate = ScrollService.isVirtual ? false : true
        ScrollService.scrollToElement(anchor, { animate: animate, mode: 'auto' })
    } else if (x !== null && y !== null) {
        ScrollService.setPosition(x, y)
    } else {
        //default chovani prohlizece
    }
}

if (!CMS) {
    const jsPluginOptions = [
        {
            from: '(.*)',
            to: '(.*)',
            in: next => next(),
            out: next => next()
        },
        {
            from: '/(cs\/hotely|de\/hotels|hotels)',
            to: 'hotel-detail',
            in: next => next(),
            out: next => {
                const hotelCardAnimation = Component.getFromElement('hotel-card-animation')

                if (hotelCardAnimation) {
                    hotelCardAnimation.animate().then(next)
                } else {
                    next()
                }
            }
        }
    ]

    const scrollStack = []

    function pushScrollPosition() {
        const location = window.location.href
        const offset = ScrollService.getScroll().y

        scrollStack.push({
            location,
            offset
        })

        if (scrollStack > 10) {
            scrollStack.shift()
        }
    }

    function popScrollPosition() {
        return scrollStack.pop()
    }

    let currentScroll = null

    const swup = new Swup({
        linkSelector: [
            'a[href^="' + window.location.origin + '"]:not([data-no-swup]):not([data-component]):not([target="_blank"]):not(.ajax)',
            'a[href^="/"]:not([data-no-swup]):not([data-component]):not([target="_blank"]):not(.ajax)',
            'a[href^="#"]:not([data-no-swup]):not([data-component]):not(.ajax)'
        ].join(', '),
        animationSelector: '.Section:not(.u-noSwup):not(.u-inCategory), .contact-Banner',
        plugins: [new SwupBodyClassPlugin(), new SwupJsPlugin(jsPluginOptions)],
        containers: ['#page-content', '#navigation', '#footer', '#modals', '#filters', '#languages'],
        skipPopStateHandling: event => {
            if (event?.state?.source === 'swup') {
                return false
            }
            return true
        }
    })

    swup.on('clickLink', event => {
        pushScrollPosition()
        if (event.delegateTarget.dataset.rel === 'hotelCard') {
            const hotelCardAnimation = Component.getFromElement('hotel-card-animation')
            hotelCardAnimation.parse(event.delegateTarget)
        }
        EventBus.emit('swup:clicklink', { event })
    })

    swup.on('willReplaceContent', event => {
        queryAll('[data-swup]').forEach(element => removeComponents(element))
        EventBus.emit('swup:beforecontentchange')
    })

    swup.on('contentReplaced', event => {
        Nette.reinit()
        
        if (android || IE || iOS || CMS || (typeof DISABLE_SMOOTH_SCROLL !== 'undefined' && DISABLE_SMOOTH_SCROLL) || document.body.classList.contains('u-nativeScroll')) {
            ScrollService.useNative()
        } else {
            ScrollService.useVirtual()
                .catch(() => ScrollService.useNative())
        }


        const id = window.location.hash.replace('#', '')
        const anchor = document.getElementById(id)

        queryAll('[data-swup]').forEach(element => {
            loadComponents(element)
            
            //na voucherpage poustet scripty rovnou a necekat na komponenty
            if (document.body.classList.contains('voucher-Page')){
                runScriptsInsideElement(element)
            }
        })

        if (currentScroll) {
            setTimeout(() => {
                ScrollService.setPosition(0, currentScroll.offset)
                currentScroll = null
            }, 0)
        } else if (id && anchor && !document.documentElement.classList.contains('to-category')) {
            const box = anchor.getBoundingClientRect()

            ScrollService.setPosition(0, box.top + ScrollService.getScroll().y)
        } else {
            ScrollService.setPosition(0, 0)
        }

        if ($.nette) {
            $.nette.load()
        }

        EventBus.emit('swup:aftercontentchange')
    })

    swup.on('transitionEnd', event => {
        EventBus.emit('swup:transitionend')
    })

    swup.on('popState', event => {
        const settings = popScrollPosition()
        if (settings?.location == window.location.href) {
            currentScroll = settings
        }
    })

    window.swup = swup
}

EventBus.on('nette:ajax:complete', () => {
    ScrollService.resize()
})