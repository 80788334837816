import { scrollToElement } from '../../services/Scroll'

document.addEventListener('nette:valid', event => {
    if (event.defaultPrevented) {
        return
    }

    const element = event.target;

    if (element.tagName.toLowerCase() != 'button') {
        const errorParent = element.closest('label') || element.parentNode
        const errorElement = errorParent.querySelector('.form-Input-error');
        if (errorElement) {
            errorParent.removeChild(errorElement)
        }

        errorParent.classList.remove('is-error')
    }

    element.classList.remove('has-error');
    element.classList.add('is-valid');
});

document.addEventListener('nette:invalid', event => {
    if (event.defaultPrevented) {
        return
    }

    const element = event.target;

    const errorParent = element.closest('label') || element.parentNode

    let errorElement = errorParent.querySelector('.form-Input-error');

    if (!errorElement) {
        errorElement = document.createElement('div');
        errorElement.className = 'form-Input-error';
    }

    errorElement.innerHTML = element.getAttribute('data-nette-error');
    errorParent.appendChild(errorElement)
    errorParent.classList.add('is-error')
    element.classList.add('has-error');
    element.classList.remove('is-valid');
});

document.addEventListener('nette:form:valid', event => {
    console.info('Form valid', event.target)

    scrollToElement(event.target, {
        offset: 200
    });
});
document.addEventListener('nette:form:invalid', event => {
    const form = event.target
    const error = form.querySelector('.form-Input-error')
    const target = error ? error.closest('.FormItem') : null
    scrollToElement(target ? target : form, {
        offset: 200
    });
});